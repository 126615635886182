<template>
  <div class="search-container">
    <b-form-input class="search" @input="onInputChange"></b-form-input>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  data() {
    return {
      timeout: null,
      inputValue: '',
    }
  },
  name: 'Search',
  components: { BFormInput },
  methods: {
    onInputChange(value) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.inputValue = value
        this.$emit('inputChange', value)
      }, 500)
    },
  },
}
</script>

