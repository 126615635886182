<template>
  <div class="number-input-container">
    <div class="number-input-input">
      <div @click="change(-1)" class="control minus">
        <img src="@/assets/images/icons/input-minus.svg" alt="" />
      </div>
      <input v-model="val" type="number" />
      <div @click="change(1)" class="control plus">
        <img src="@/assets/images/icons/input-plus.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: this.value,
    }
  },
  props: {
    value: { type: Number, default: 0 },
    max: { type: Number, default: null }
  },
  watch: {
    value(newVal) {
      this.val = parseInt(newVal, 10)
      if(this.max && newVal > this.max) {
          this.val = this.max
      }
      if(Number.isNaN(newVal)) {
        this.val = 0
      }
      this.$emit('input', parseInt(this.val, 10))
    },
    val(newVal) {
      this.val = parseInt(newVal, 10)
      if(this.max && newVal > this.max) {
          this.val = this.max
      }
      if(Number.isNaN(newVal)) {
        this.val = 0
      }
      this.$emit('input', parseInt(this.val, 10))
    }
  },
  methods: {
    change(offset) {
      if(this.val === 0 && offset < 0) {
        this.val = 0  
      } else {
        this.val += offset
        this.val = parseInt(this.val, 10)
      }
      if(this.max && this.val > this.max) {
          this.val = this.max
      }
    },
  },
}
</script>
<style scoped lang="scss">
.number-input-container {
  display: flex;
  * {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .number-input-input {
    display: flex;
    align-items: center;
    margin-left: auto;
    .control {
      background: rgba(72, 72, 72, 0.5);
      border-radius: 100px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    input {
      width: 30px;
      border: none;
      background: transparent;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
</style>
