<template>
  <div class="courier-stock">
    <title-bar
      title="Stock"
      :storeBtn="onOff === true && car !== null"
      @storeFromStock="showStockModal = true"
    />
    <search
      v-if="onOff === true && car !== null"
      class="mb-4"
      @inputChange="inputChange"
    />

    <b-alert :show="onOff === false || car === null" variant="warning"
      >You are not Available or you have no Car assigned</b-alert
    >

    <div v-if="onOff === true && car !== null">
      <b-button
        class="make-order-btn"
        variant="success"
        @click="startOrder = true"
        >Make an order</b-button
      >

      <div class="order-confirm-block" :class="{ active: startOrder }">
        <div class="order-confirm-block-total">
          <span>Total</span>
          <span>{{ total | price }}</span>
        </div>
        <div class="order-confirm-block-buttons">
          <b-button
            :disabled="total === 0"
            variant="success"
            @click="showModal = true"
            >Create an order</b-button
          >
          <b-button variant="warning" @click="cancelOrder()">
            <img src="@/assets/images/icons/xmark.svg" alt="" />
          </b-button>
        </div>
      </div>

      <b-modal v-model="showModal" dialog-class="no-header">
        <template #modal-header>
          <div @click="showModal = false" class="modal-close">
            <img src="@/assets/images/icons/xmark.svg" alt="" />
          </div>
        </template>

        Payment selection

        <template #modal-footer>
          <div class="payments">
            <div @click="confirm('CASH')">
              {{ $helper.getEnumTranslation('order_payment_method', 'CASH') }}
            </div>
            <div @click="confirm('CARD')">
              {{ $helper.getEnumTranslation('order_payment_method', 'CARD') }}
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal v-model="showStockModal" dialog-class="no-header">
        <template #modal-header>
          <div @click="showModal = false" class="modal-close">
            <img src="@/assets/images/icons/xmark.svg" alt="" />
          </div>
        </template>

        Store goods from stock?

        <template #modal-footer>
          <div class="payments">
            <div @click="showStockModal = false">No</div>
            <div @click="$router.push('/stock-control')">Yes</div>
          </div>
        </template>
      </b-modal>

      <div v-for="(category, categoryIndex) of categories" :key="categoryIndex">
        <div
          v-if="category.products.length"
          class="product-listing"
          :class="{ active: startOrder }"
        >
          <div class="product-listing-title">{{ category.name }}</div>
          <div
            v-for="(product, index) of category.products"
            :key="index"
            class="product-listing-item"
          >
            <div class="product">
              <div class="product-image">
                <img src="" alt="" />
              </div>
              <div class="product-name">{{ product.name }}</div>
            </div>
            <div class="price">{{ product.price | price }}</div>
            <div class="stock-count">{{ getProductStockCount(product) }}</div>
            <number-input
              @input="updateCart(product)"
              :max="getProductStockCount(product)"
              v-model="product.toOrder"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import NumberInput from '@/views/components/Inputs/NumberInput.vue'
import Search from '@/views/components/Inputs/Search.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    NumberInput,
    Search,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      onOff: this.$store.state.onOff,
      search: '',
      startOrder: false,
      showModal: false,
      showStockModal: false,
      products: null,
      car: null,
      categories: null,
      cart: [],
      total: 0,
    }
  },
  created() {
    this.loadCategories()
    this.$Cars
      .getCollection({ params: { courier: this.userData['@id'] } }, 'car_list')
      .then(carResponse => {
        if (carResponse.data['hydra:member'].length) {
          // eslint-disable-next-line prefer-destructuring
          this.car = carResponse.data['hydra:member'][0]
          this.$store.dispatch('setCourierCar', this.car['@id'])
          this.loadStock()
        }
      })
  },
  methods: {
    confirm(type) {
      const body = {
        status: 'CLOSED',
        financialStatus: 'PAID',
        paymentMethod: type,
        items: [],
        courier: this.userData['@id'],
        createdBy: this.userData["@id"]
      }
      this.cart.forEach(product => {
        if (product.toOrder > 0) {
          body.items.push({
            productVariantId: product.id,
            quantity: product.toOrder,
          })
        }
      })

      this.$Orders
        .createResource({ body })
        .then(response => {
          if (response.status === 201) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Order completed',
              '',
              'success',
            )
            this.showModal = false
            this.startOrder = false
            this.total = 0
            this.cart = []
            this.loadCategories()
            this.loadStock()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
    cancelOrder() {
      this.startOrder = false
      this.total = 0
      this.cart = []
      this.categories.forEach(category => {
        category.products.forEach(product => {
          product.toOrder = 0
        })
      })
    },
    confirmStockModal() {
      this.showStockModal = false
    },
    inputChange(value) {
      this.search = value
      this.loadStock()
    },
    loadStock() {
      if (this.categories && this.categories.length) {
        this.categories.forEach(category => {
          category.products = []
        })
      }
      const params = {
        pagination: false,
      }
      if (this.search !== '') {
        params.search = this.search
      }
      this.$ProductVariants
        .getCollection({ params }, 'product_variant_list')
        .then(response => {
          this.products = response.data['hydra:member']
          this.products.forEach(product => {
            const category = this.categories.find(
              cat => cat['@id'] === product.category,
            )
            category.products.push({ ...product, toOrder: 0 })
          })
        })
    },
    loadCategories() {
      this.$Categories.getCollection({}, 'category_list').then(response => {
        this.categories = response.data['hydra:member']
        this.categories = this.categories.map(category => ({
          ...category,
          products: [],
        }))
      })
    },
    getProductStockCount(product) {
      const stock = product.inventories.find(
        inv => inv.car === this.car['@id'],
      )
      return stock.available
    },
    updateCart(product) {
      this.total = 0
      const existingProduct = this.cart.find(p => p.id === product.id)
      if (existingProduct) {
        existingProduct.toOrder = product.toOrder
      } else {
        this.cart.push(product)
      }
      this.cart.forEach(p => {
        this.total += p.price * p.toOrder
      })
    },
  },
}
</script>
